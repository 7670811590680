<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <!-- skeleton  -->
            <form class="needs-validation" @submit.prevent="formDocCus">
              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <div class="col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="branchId">สาขา:</label>

                        <multiselect
                          id="branchId"
                          v-model="form.branchId"
                          :options="localData"
                          class=""
                          :class="{
                            'is-invalid': submitform && $v.form.branchId.$error,
                          }"
                          label="nameTh"
                          :show-labels="false"
                          track-by="nameTh"
                          @input="handleSearch"
                        ></multiselect>
                        <div
                          v-if="submitform && $v.form.branchId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.branchId.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-6">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="regJobDate"> วันที่:</label>

                        <date-picker
                          v-model="form.regJobDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                          :class="{
                            'is-invalid':
                              submitform && $v.form.regJobDate.$error,
                          }"
                        ></date-picker>
                        <div
                          v-if="submitform && $v.form.regJobDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.regJobDate.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-md-9 col-sm-9 col-9">
                          <div class="mb-3 position-relative">
                            <label for="regCode">
                              เลือกจากเลขที่ใบแจ้งจำหน่าย:</label
                            >
                            <!-- disabled="disabled"  -->
                            <multiselect
                              id="regCode"
                              v-model="form.regCode"
                              :options="rowRegDoc"
                              style="ts-15"
                              placeholder="ค้นหาเลขที่ใบแจ้งจำหน่าย"
                              label="regCode"
                              :show-labels="false"
                              track-by="regCode"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              @search-change="getRegDoc($event)"
                              :custom-label="customLabelReg"
                              @input="Selected(form.regCode)"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-3">
                          <div class="mt-2 position-relative">
                            <b-button
                              class="btn"
                              variant="info"
                              v-b-modal.modalReg
                            >
                              <i class="uil-file-search-alt"></i
                            ></b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-sm-9 col-md-9">
                          <div class="mb-3 position-relative">
                            <code>* </code
                            ><label for="customerNameTh">ชื่อลูกค้า:</label>

                            <multiselect
                              id="customerNameTh"
                              placeholder="ค้นหาชื่อลูกค้า"
                              v-model="form.customerFullNameTh"
                              :options="rowsCus"
                              class=""
                              :class="{
                                'is-invalid':
                                  submitform &&
                                  $v.form.customerFullNameTh.$error,
                              }"
                              :custom-label="customLabelCus"
                              label="nameTh"
                              :show-labels="false"
                              track-by="cusId"
                              @search-change="getCustomer($event)"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              ><span slot="noResult"
                                >ไม่พบข้อมูล</span
                              ></multiselect
                            >
                            <div
                              v-if="
                                submitform && $v.form.customerFullNameTh.$error
                              "
                              class="invalid-tooltip"
                            >
                              <span
                                v-if="!$v.form.customerFullNameTh.required"
                                >{{ message }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-3">
                          <div class="mt-2 position-relative">
                            <b-button
                              class="btn"
                              variant="info"
                              v-b-modal.modalCus
                            >
                              <i class="uil-file-search-alt"></i
                            ></b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-9 col-md-9 col-sm-9">
                          <div class="mb-3 position-relative">
                            <code>* </code
                            ><label for="vehicleId">ทะเบียนรถ:</label>
                            <multiselect
                              placeholder="ค้นหาทะเบียนรถ"
                              id="vehicleId"
                              v-model="form.vehicleId"
                              :options="rowsVehi"
                              class=""
                              :class="{
                                'is-invalid':
                                  submitform && $v.form.vehicleId.$error,
                              }"
                              label="licensePlate"
                              :show-labels="false"
                              track-by="vehicleId"
                              @search-change="getDataVehicles($event)"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              :custom-label="customLabelVi"
                              @input="selectViOb(form.vehicleId)"
                              ><span slot="noResult"
                                >ไม่พบข้อมูล</span
                              ></multiselect
                            >
                            <div
                              v-if="submitform && $v.form.vehicleId.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.form.vehicleId.required">{{
                                message
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-3">
                          <div class="mt-2 position-relative">
                            <b-button
                              class="btn"
                              variant="info"
                              v-b-modal.modalVe
                            >
                              <i class="uil-file-search-alt"></i
                            ></b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="vin">เลขตัวถัง:</label>

                        <b-form-input
                          disabled
                          id="vin"
                          v-model="form.vin"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="modelNameTh">รุ่น:</label>

                        <b-form-input
                          disabled
                          id="modelNameTh"
                          v-model="form.modelNameTh"
                          type="text"
                          class="form-control"
                          value="Otto"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                      <div class="row align-items-center">
                        <div class="col-9 col-md-9 col-sm-9">
                          <div class="mb-3 position-relative">
                            <label for="paymentId">เลขที่ใบเสร็จรับเงิน:</label>
                            <multiselect
                              id="paymentId"
                              v-model="form.paymentId"
                              :options="rowPayment"
                              style="ts-15"
                              placeholder="เลขที่ใบเสร็จรับเงิน"
                              label="paymentCode"
                              :show-labels="false"
                              track-by="paymentId"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              :custom-label="customLabelPay"
                              @search-change="getPayment($event)"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-3 col-3">
                          <div class="mt-2 position-relative">
                            <b-button
                              class="btn"
                              variant="info"
                              v-b-modal.modalPay
                            >
                              <i class="uil-file-search-alt"></i
                            ></b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-4">
                      <div class="mb-2">
                        <code>* </code
                        ><label class="form-label d-block mb-3"></label>
                        <b-form-radio-group
                          v-model="form.isSale"
                          :options="optionSale"
                          name="radios-stacked"
                          stacked
                          :class="{
                            'is-invalid': submitform && $v.form.isSale.$error,
                          }"
                        >
                        </b-form-radio-group>
                        <div
                          v-if="submitform && $v.form.isSale.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.isSale.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-5">
                      <div class="mb-3 position-relative">
                        <label for="note">หมายเหตุ:</label>
                        <!-- disabled="disabled"  -->
                        <textarea
                          v-model="form.note"
                          class="form-control"
                          name="textarea"
                        ></textarea>
                      </div>
                    </div>

                    <!-- <div class="row">
                      <div class="col-md-3 col-sm-6">
                        <div class="position-relative">
                          <code>* </code
                          ><label for="docTypeId">ประเภทเอกสาร:</label>
                          <multiselect
                            id="docTypeId"
                            v-model="form.docTypeId"
                            :options="rowDocType"
                            style="ts-15"
                            placeholder="ประเภทเอกสาร"
                            label="nameTh"
                            :show-labels="false"
                            track-by="nameTh"
                            :loading="loading"
                            :close-on-select="true"
                            :internal-search="true"
                            open-direction="bottom"
                            @search-change="getDocType()"
                            :class="{
                              'is-invalid':
                                submitform && $v.form.docTypeId.$error,
                            }"
                          >
                            <span slot="noResult">ไม่พบข้อมูล</span>
                          </multiselect>
                          <div
                            v-if="submitform && $v.form.docTypeId.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.form.docTypeId.required">{{
                              message
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <div class="row align-items-center">
                          <div class="col-md-12 col-sm-12">
                            <div class="position-relative">
                              <code>* </code
                              ><label for="docTypeId">แนบเอกสาร:</label>

                              <b-form-file
                                ref="img"
                                id="img"
                                v-model="form.image"
                                accept="image/jpeg, image/png, image/gif"
                                class="form-control"
                                plain
                                :class="{
                                  'is-invalid':
                                    submitform && $v.form.docTypeId.$error,
                                }"
                              ></b-form-file>
                              <div
                                v-if="submitform && $v.form.docTypeId.$error"
                                class="invalid-tooltip"
                              >
                                <span v-if="!$v.form.docTypeId.required">{{
                                  message
                                }}</span>
                              </div>
                            </div>
                          </div>
                      
                        </div>
                      </div>
                    </div> -->
                  </div>

                  <br />

                  <div class="col-md">
                    <button class="btn btn-success float-end" type="submit">
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title-desc">รายการเอกสาร</p>
              </div>
              <div class="col-6">
                <button class="btn btn-primary float-end">อัพโหลดเอกสาร</button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">ประเภทเอกสาร</th>
                    <th scope="col">รูปภาพ/ไฟล์</th>
 
                    <th scope="col"></th>
                  </tr>
                </thead>
 
                <tbody>
                  <tr>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div> -->
      </div>
      <!-- end card -->
    </div>
    <b-modal
      ref="modalReg"
      id="modalReg"
      title="รายการแจ้งจำหน่าย"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-sm-4 col-md-4">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center"> สาขา:</label>
                  <multiselect
                    v-model="form.branchId"
                    :options="localData"
                    label="nameTh"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  เลขที่ใบแจ้งจำหน่าย:
                  <b-form-input
                    v-model="filter.regCode"
                    type="search"
                    placeholder="เลขที่ใบแจ้งจำหน่าย"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  ชื่อลูกค้า:
                  <b-form-input
                    v-model="filter.customerNameTh"
                    type="search"
                    placeholder="ชื่อลูกค้า"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  นามสกุลลูกค้า:
                  <b-form-input
                    v-model="filter.customerFamilyNameTh"
                    type="search"
                    placeholder="นามสกุลลูกค้า"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="mb-4 position-relative">
                  <label class="d-inline align-items-center m-2">
                    วันที่:</label
                  >

                  <date-picker
                    v-model="filter.regDate"
                    :first-day-of-week="1"
                    format="YYYY-MM-DD"
                    @input="handleSearch"
                    value-type="format"
                    lang="en"
                  ></date-picker>
                </div>
              </div>

              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearch"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="rowRegDoc"
              :fields="fields"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="onRowSelected"
            >
              <template #cell(regDate)="rowRegDoc">
                <changeDate :date="rowRegDoc.item.regDate"></changeDate>
              </template>
              <template #cell(index)="rowRegDoc" v-if="this.currentPage > 1">
                {{ rowRegDoc.index + 1 + (currentPage * perPage - perPage) }}
              </template>
              <template #cell(index)="rowRegDoc" v-else>
                {{ rowRegDoc.index + 1 }}
              </template>
              <template #cell(status)="rowRegDoc">
                <span
                  class="badge bg-warning font-size-12 ms-2"
                  v-if="rowRegDoc.item.status === 'DFT'"
                >
                  ร่าง
                </span>
                <span
                  class="badge bg-info font-size-12 ms-2"
                  v-if="rowRegDoc.item.status === 'SUBMIT'"
                >
                  รออนุมัติ
                </span>
                <span
                  class="badge bg-success font-size-12 ms-2"
                  v-if="rowRegDoc.item.status === 'APV'"
                >
                  ยืนยันแล้ว
                </span>
                <span
                  class="badge bg-danger font-size-12 ms-2"
                  v-if="rowRegDoc.item.status === 'CAN'"
                >
                  ยกเลิกแล้ว
                </span>
              </template>
              <!-- End edit table -->
            </b-table>
          </div>

          <div class="row">
            <span v-if="this.totalRecord === 0" class="text-center font-size-16"
              >ไม่พบรายการ</span
            >
            <div class="col" v-if="this.totalRecord > 0">
              <div class="col">
                หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                {{ totalRecord }} รายการ
              </div>

              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRecord"
                      :per-page="perPage"
                      @change="handleChangePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modalVe"
      id="modalVe"
      title="รายการรถ"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-sm-4 col-md-3">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center"> สาขา:</label>
                  <multiselect
                    v-model="form.branchId"
                    :options="localData"
                    label="nameTh"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2 text-end">
                  ทะเบียนรถ:
                  <b-form-input
                    v-model="filter.licensePlate"
                    type="search"
                    placeholder="ค้นหาทะเบียนรถ"
                    class="form-control ms-2"
                    @keyup.enter="handleSearchVe"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2 text-end">
                  เลขตัวถัง:
                  <b-form-input
                    v-model="filter.vin"
                    type="search"
                    placeholder="ค้นหาเลขตัวถัง"
                    class="form-control ms-2"
                    @keyup.enter="handleSearchVe"
                  ></b-form-input>
                </label>
              </div>
              <!-- <div class="col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2 text-end">
                  ยี่ห้อ:
                  <b-form-input
                    v-model="filter.vehicleBrandTh"
                    type="search"
                    placeholder="ค้นหายี่ห้อ"
                    class="form-control ms-2"
                    @keyup.enter="handleSearchVe"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2 text-end">
                  รุ่นรถ:
                  <b-form-input
                    v-model="filter.vehicleModelTh"
                    type="search"
                    placeholder="ค้นหารุ่นรถ"
                    class="form-control ms-2"
                    @keyup.enter="handleSearchVe"
                  ></b-form-input>
                </label>
              </div> -->

              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearchVe"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPageVe"
                        size="sm"
                        :options="pageOptionsVe"
                        @input="handlePageChangeVe"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="rowsVehi"
              :fields="fieldsVe"
              responsive="true"
              :per-page="perPageVe"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="selectVi"
            >
              <template #cell(regDate)="rowsVehi">
                <changeDate :date="rowsVehi.item.regDate"></changeDate>
              </template>
              <template #cell(index)="rowsVehi" v-if="this.currentPage > 1">
                {{ rowsVehi.index + 1 + (currentPage * perPage - perPage) }}
              </template>
              <template #cell(index)="rowsVehi" v-else>
                {{ rowsVehi.index + 1 }}
              </template>
              <template #cell(status)="rowsVehi">
                <span
                  class="badge bg-warning font-size-12 ms-2"
                  v-if="rowsVehi.item.status === 'DFT'"
                >
                  ร่าง
                </span>
                <span
                  class="badge bg-info font-size-12 ms-2"
                  v-if="rowsVehi.item.status === 'SUBMIT'"
                >
                  รออนุมัติ
                </span>
                <span
                  class="badge bg-success font-size-12 ms-2"
                  v-if="rowsVehi.item.status === 'APV'"
                >
                  ยืนยันแล้ว
                </span>
                <span
                  class="badge bg-danger font-size-12 ms-2"
                  v-if="rowsVehi.item.status === 'CAN'"
                >
                  ยกเลิกแล้ว
                </span>
              </template>
              <!-- End edit table -->
            </b-table>
          </div>

          <div class="row">
            <span v-if="this.totalPageVe === 0" class="text-center font-size-16"
              >ไม่พบรายการ</span
            >
            <div class="col" v-if="this.totalPageVe > 0">
              <div class="col">
                หน้า {{ currentPageVe }} จาก {{ this.totalPageVe }} ทั้งหมด
                {{ this.totalRecordVe }} รายการ
              </div>

              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPageVe"
                      :total-rows="totalRecordVe"
                      :per-page="perPageVe"
                      @change="handleChangePageVe"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="modalPay"
      id="modalPay"
      title="รายการใบเสร็จรับเงิน"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-sm-4 col-md-4">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center"> สาขา:</label>
                  <multiselect
                    v-model="form.branchId"
                    :options="localData"
                    label="nameTh"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  เลขที่ใบเสร็จรับเงิน:
                  <b-form-input
                    v-model="filter.paymentCode"
                    type="search"
                    placeholder="เลขที่ใบเสร็จรับเงิน"
                    class="form-control ms-2"
                    @keyup.enter="handleSearchPay"
                  ></b-form-input>
                </label>
              </div>

              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearchPay"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPagePay"
                        size="sm"
                        :options="pageOptionsPay"
                        @input="handlePageChangePay"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="rowPayment"
              :fields="fieldsPay"
              responsive="true"
              :per-page="perPagePay"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="selectPay"
            >
              <template #cell(index)="rowPayment" v-if="this.currentPage > 1">
                {{ rowPayment.index + 1 + (currentPage * perPage - perPage) }}
              </template>
              <template #cell(index)="rowPayment" v-else>
                {{ rowPayment.index + 1 }}
              </template>
              <template #cell(paymentDate)="rowPayment">
                <changeDate :date="rowPayment.item.paymentDate"></changeDate>
              </template>
              <template #cell(status)="rowPayment">
                <span
                  class="badge bg-warning font-size-12 ms-2"
                  v-if="rowPayment.item.status === 'DFT'"
                >
                  ร่าง
                </span>
                <span
                  class="badge bg-info font-size-12 ms-2"
                  v-if="rowPayment.item.status === 'SUBMIT'"
                >
                  รออนุมัติ
                </span>
                <span
                  class="badge bg-success font-size-12 ms-2"
                  v-if="rowPayment.item.status === 'APV'"
                >
                  ยืนยันแล้ว
                </span>
                <span
                  class="badge bg-danger font-size-12 ms-2"
                  v-if="rowPayment.item.status === 'CAN'"
                >
                  ยกเลิกแล้ว
                </span>
              </template>
              <!-- End edit table -->
            </b-table>
          </div>

          <div class="row">
            <span
              v-if="this.totalRecordPay === 0"
              class="text-center font-size-16"
              >ไม่พบรายการ</span
            >
            <div class="col" v-if="this.totalRecordPay > 0">
              <div class="col">
                หน้า {{ currentPagePay }} จาก {{ this.totalPagePay }} ทั้งหมด
                {{ totalRecordPay }} รายการ
              </div>

              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPagePay"
                      :total-rows="totalRecordPay"
                      :per-page="perPagePay"
                      @change="handleChangePagePay"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="modalCus"
      id="modalCus"
      title="รายชื่อลูกค้า"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-sm-4 col-md-4">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center"> สาขา:</label>
                  <multiselect
                    v-model="form.branchId"
                    :options="localData"
                    label="nameTh"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  ชื่อลูกค้า:
                  <b-form-input
                    v-model="filter.nameCus"
                    type="search"
                    placeholder="ชื่อลูกค้า"
                    class="form-control ms-2"
                    @keyup.enter="handleSearchCus"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  นามสกุลลูกค้า:
                  <b-form-input
                    v-model="filter.familyCus"
                    type="search"
                    placeholder="นามสกุลลูกค้า"
                    class="form-control ms-2"
                    @keyup.enter="handleSearchCus"
                  ></b-form-input>
                </label>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  เบอร์โทร:
                  <b-form-input
                    v-model="filter.mobilePhone"
                    type="search"
                    placeholder="เบอร์โทร"
                    class="form-control ms-2"
                    @keyup.enter="handleSearchCus"
                  ></b-form-input>
                </label>
              </div>
              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearchCus"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPageCus"
                        size="sm"
                        :options="pageOptionsCus"
                        @input="handlePageChangeCus"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="rowsCus"
              :fields="fieldsCus"
              responsive="true"
              :per-page="perPageCus"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="selectCus"
            >
              <template #cell(index)="rowsCus" v-if="this.currentPage > 1">
                {{ rowsCus.index + 1 + (currentPage * perPage - perPage) }}
              </template>
              <template #cell(index)="rowsCus" v-else>
                {{ rowsCus.index + 1 }}
              </template>
              <template #cell(cusType)="rowsCus">
                <span v-if="rowsCus.item.cusType === 'P'"> บุคคล </span>
                <span v-if="rowsCus.item.cusType === 'B'"> นิติบุคคล </span>
              </template>
              <template #cell(paymentDate)="rowsCus">
                <changeDate :date="rowsCus.item.paymentDate"></changeDate>
              </template>
              <template #cell(status)="rowsCus">
                <span
                  class="badge bg-warning font-size-12 ms-2"
                  v-if="rowsCus.item.status === 'DFT'"
                >
                  ร่าง
                </span>
                <span
                  class="badge bg-info font-size-12 ms-2"
                  v-if="rowsCus.item.status === 'SUBMIT'"
                >
                  รออนุมัติ
                </span>
                <span
                  class="badge bg-success font-size-12 ms-2"
                  v-if="rowsCus.item.status === 'APV'"
                >
                  ยืนยันแล้ว
                </span>
                <span
                  class="badge bg-danger font-size-12 ms-2"
                  v-if="rowsCus.item.status === 'CAN'"
                >
                  ยกเลิกแล้ว
                </span>
              </template>
              <!-- End edit table -->
            </b-table>
          </div>

          <div class="row">
            <span
              v-if="this.totalRecordCus === 0"
              class="text-center font-size-16"
              >ไม่พบรายการ</span
            >
            <div class="col" v-if="this.totalRecordCus > 0">
              <div class="col">
                หน้า {{ currentPageCus }} จาก {{ this.totalPageCus }} ทั้งหมด
                {{ totalRecordCus }} รายการ
              </div>

              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPageCus"
                      :total-rows="totalRecordCus"
                      :per-page="perPageCus"
                      @change="handleChangePageCus"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end">ตกลง</button>
        </div>
      </div>
    </b-modal>
    <!-- end col -->
  </Layout>
</template>
<script>
import {
  required,
  //   maxValue,
  //   minValue,
  //   maxLength,
  //   numeric,
} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import DatePicker from "vue2-datepicker";
import ChangeDate from "@/components/changeDateComponent";
// import vue2Dropzone from "vue2-dropzone";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    ChangeDate,
    // vueDropzone: vue2Dropzone,
  },
  page: {
    title: appConfig.receiveDoc,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      loading: undefined,
      message: "โปรดตรวจสอบข้อมูล",
      title: "สร้างการรับเอกสาร",
      items: [
        {
          text: "งานทะเบียน",
          active: true,
        },
        {
          text: "รับเอกสาร",
          href: "/receive-docCus",
        },
        {
          text: "สร้างการรับเอกสาร",
          active: true,
        },
      ],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value",
        },
      },
      rowSale: [],
      optionCus: [],
      localData: [],
      localDataBranchUser: [],
      localDataBranchIdUser: [],
      optionsYear: [],
      filter: {
        licensePlate: "",
        regCode: "",
        customerFamilyNameTh: "",
        customerNameTh: "",
        regDate: "",
        paymentCode: "",
        nameTh: "",
        familyNameTh: "",
        vin: "",
        nameCus: "",
        familyCus: "",
        mobilePhone: "",
      },
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "regCode",
          sortable: true,
          label: "เลขที่ใบแจ้งจำหน่าย",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },

        {
          key: "regDate",
          sortable: true,
          label: "วันที่",
        },

        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },
        {
          key: "vehicleModelTh",
          sortable: true,
          label: "รุ่นรถ",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      fieldsVe: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "vin",
          sortable: true,
          label: "เลขตัวถัง",
        },
        {
          key: "ownerNameTh",
          sortable: true,
          label: "เจ้าของรถ",
        },
        {
          key: "driverNameTh",
          sortable: true,
          label: "คนขับ",
        },

        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },
        {
          key: "vehicleBrandTh",
          sortable: true,
          label: "ยี่ห้อ",
        },
        {
          key: "vehicleModelTh",
          sortable: true,
          label: "รุ่น",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      fieldsPay: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "paymentCode",
          sortable: true,
          label: "เลขที่ใบเสร็จรับเงิน",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },

        {
          key: "paymentDate",
          sortable: true,
          label: "วันที่",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ทะเบียนรถ",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },

        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      fieldsCus: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "cusCode",
          sortable: true,
          label: "รหัสลูกค้า",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "familyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },

        {
          key: "mobilePhone",
          sortable: true,
          label: "เบอร์โทร",
        },
        {
          key: "cusType",
          sortable: true,
          label: "ประเภทลูกค้า",
        },

        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
      ],
      rowImg: [],
      provinces: [],
      amphures: [],
      districts: [],
      branchId: "",
      partId: "",
      roId: "",
      cusId: "",
      vehicleId: "",
      note: "",
      plsDate: "",
      price: "",
      cause: "",
      customerDecision: "",
      deposit: "",
      appointmentDate: "",
      customerName: "",
      customerMolbileNumber: "",
      psNsArriveDate: "",
      psStockAmount: "",

      totalPageCus: "",
      perPageCus: 10,
      currentPageCus: 1,
      totalRecordCus: "",
      pageOptionsCus: [5, 10, 25, 50, 100],

      totalPageVe: "",
      perPageVe: 10,
      currentPageVe: 1,
      totalRecordVe: "",
      pageOptionsVe: [5, 10, 25, 50, 100],

      totalPagePay: "",
      perPagePay: 10,
      currentPagePay: 1,
      totalRecordPay: "",
      pageOptionsPay: [5, 10, 25, 50, 100],

      branchIdSearch: "",
      branchIdRo: "",
      rowRegDoc: [],
      page: "",
      totalPage: "",
      perPage: 10,
      currentPage: 1,
      rowpage: "",
      totalRecord: "",
      rowTest: [],
      totalRows: 1,
      rowPayment: [],
      pageOptions: [5, 10, 25, 50, 100],
      selectMode: "single",
      selected: [],
      isLoading: "",
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      optionsGroupId: [],
      optionsUnits: [],
      options: [],
      use: [
        { text: "ใช้กับศูนย์ซ่อมสีและตัวถัง", value: "1" },
        { text: "ใช้กับศูนย์บริการ", value: "2" },
      ],
      opcodeType: [
        {
          name: "อะไหล่นอก",
          id: "EX",
        },
        {
          name: "กำหนดเอง",
          id: "CUSTOM",
        },
      ],
      optionSale: [
        { value: "1", text: "รถใหม่" },
        { value: "0", text: "รถต่อทะเบียน" },
      ],
      rowDocType: [],
      optionRegCode: [],
      isDisabled: false,
      rowsVehi: [],
      rowsCus: [],
      optionTypeDoc: [],
      form: {
        isSale: "",
        paymentId: "",
        image: null,
        docTypeId: "",
        modelNameTh: "",
        vin: "",
        regJobDate: "",
        regCode: "",
        typeDoca: "",
        branchId: "",
        partId: "",
        cusId: "",
        vehicleId: "",
        note: "",
        customerName: "",
        customerFullNameTh: "",
      },
      file: null,
      docId: "",
      submitform: false,
      submit: false,
    };
  },

  validations: {
    form: {
      branchId: {
        required,
      },
      regJobDate: {
        required,
      },
      customerFullNameTh: {
        required,
      },
      vehicleId: {
        required,
      },

      isSale: {
        required,
      },
    },
  },
  // watch: {
  //   file(val) {
  //     if (!val) return;
  //     if (this.previewImg) {
  //       this.previewImg.remove();
  //     }
  //     const img = document.createElement("img");
  //     img.classList.add("obj");
  //     img.file = this.file;
  //     this.previewImg = img;
  //     this.$refs.preview.appendChild(img);
  //     const fileReader = new FileReader();
  //     fileReader.onload = (e) => {
  //       this.previewImg.src = e.target.result;
  //     };
  //     fileReader.readAsDataURL(this.file);
  //     console.log(fileReader);
  //   },
  // },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if (user.branchId == item.branchId) {
        this.form.branchId = { branchId: item.branchId, nameTh: item.nameTh };
      }
    });
    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
    // this.filter.branch = {branchId: user.branchId}
    // console.log(user.branchId);
    this.getRegDoc();
    this.getCustomer();
    this.getDataVehicles();
    this.getPayment();
    this.getDocType();
    this.getNow();
    // this.getDataloadImage();
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();
      // today.getDate();

      this.form.regJobDate = date;
    },
    customLabelCus({ nameTh, familyNameTh }) {
      return `${nameTh != null ? nameTh : ""}  ${
        familyNameTh != null ? familyNameTh : " "
      }`;
    },
    customLabelPay({ paymentCode }) {
      return `${paymentCode != null ? paymentCode : " "} `;
    },
    customLabelVi({ licensePlate }) {
      return `${licensePlate != null ? licensePlate : " "} `;
    },
    customLabelReg({ customerNameTh, customerFamilyNameTh, regCode }) {
      return `${regCode != null ? regCode : " "} -[${
        customerNameTh != null ? customerNameTh : ""
      } ${customerFamilyNameTh != null ? customerFamilyNameTh : ""}] `;
    },
    Selected(data) {
      console.log(data);
      this.selected = data;
      var regCode = {
        regCode:
          this.selected.regCode != undefined ? this.selected.regCode : "",
        regId: this.selected.regId,
        customerNameTh: this.selected.customerNameTh,
        customerFamilyNameTh: this.selected.customerFamilyNameTh,
      };

      var Cus = {
        cusId: this.selected.cusId,
        nameTh: this.selected.customerNameTh,
        familyNameTh: this.selected.customerFamilyNameTh,
      };

      this.form.customerFullNameTh = Cus;
      this.form.regCode = regCode;
      this.form.vehicleId = {
        licensePlate: this.selected.licensePlate,
        vehicleId: this.selected.vehicleId,
      };
      //log(this.form.customerFullNameTh);

      this.form.modelNameTh = this.selected.vehicleModelTh;
      this.form.vin = this.selected.vin;

      this.form.paymentId = {
        paymentCode: this.selected.payment_code,
        paymentId: this.selected.paymentId,
      };
    },
    onRowSelected(rowRegDoc) {
      //log(rowRegDoc);

      this.selected = rowRegDoc[0];
      var regCode = {
        regCode:
          this.selected.regCode != undefined ? this.selected.regCode : "",
        regId: this.selected.regId,
        customerNameTh: this.selected.customerNameTh,
        customerFamilyNameTh: this.selected.customerFamilyNameTh,
      };

      var Cus = {
        cusId: this.selected.cusId,
        nameTh: this.selected.customerNameTh,
        familyNameTh: this.selected.customerFamilyNameTh,
      };

      this.form.customerFullNameTh = Cus;
      this.form.regCode = regCode;
      this.form.vehicleId = {
        licensePlate: this.selected.licensePlate,
        vehicleId: this.selected.vehicleId,
      };
      //log(this.form.customerFullNameTh);

      this.form.modelNameTh = this.selected.vehicleModelTh;
      this.form.vin = this.selected.vin;

      this.form.paymentId = {
        paymentCode: this.selected.payment_code,
        paymentId: this.selected.paymentId,
      };
      this.$refs["modalReg"].hide();
    },

    selectVi(data) {
      if (data[0] != null) {
        this.data = data[0];
        var vehicleId = {
          licensePlate: this.data.licensePlate,
          ownerNameTh: this.data.ownerNameTh,
          vehicleId: this.data.vehicleId,
        };
        this.form.vin = this.data.vin;
        this.form.modelNameTh = this.data.vehicleModelTh;
        this.form.vehicleId = vehicleId;
        this.form.customerFullNameTh = {
          cusId: this.data.ownerId,
          nameTh: this.data.ownerNameTh,
        };
      }

      this.$refs["modalVe"].hide();
      //log(data);
    },
    selectViOb(data) {
      if (data != null) {
        this.data = data;
        var vehicleId = {
          licensePlate: this.data.licensePlate,
          ownerNameTh: this.data.ownerNameTh,
          vehicleId: this.data.vehicleId,
        };
        this.form.vin = this.data.vin;
        this.form.modelNameTh = this.data.vehicleModelTh;
        this.form.vehicleId = vehicleId;
      } else {
        this.form.vin = "";
        this.form.modelNameTh = "";
        this.form.vehicleId = "";
      }
    },
    selectPay(data) {
      this.data = data[0];
      var paymentId = {
        paymentCode: this.data.paymentCode,
        customerNameTh: this.data.customerNameTh,
        customerFamilyNameTh: this.data.customerFamilyNameTh,
      };
      this.form.paymentId = paymentId;
      this.$refs["modalPay"].hide();
      //log(data);
    },
    selectCus(data) {
      this.data = data[0];
      var cusId = {
        cusId: this.data.cusId,
        nameTh: this.data.nameTh,
        familyNameTh: this.data.familyNameTh,
      };
      this.form.customerFullNameTh = cusId;
      this.$refs["modalCus"].hide();
      //log(data);
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;
      this.getRegDoc();
    },
    getRegDoc: function (event) {
      this.loading = true;
      // this.branchIdRo = branchId;
      useNetw
        .get("api/registration-document/branch-vehicle-registration", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            regCode: event != null ? event : this.filter.regCode,
            customerNameTh: this.filter.customerNameTh,
            customerFamilyNameTh: this.filter.customerFamilyNameTh,

            regDate: this.filter.regDate,
          },
        })
        .then((response) => {
          //log(response);
          this.rowRegDoc = response.data.data;
          this.svId = response.data.data.svId;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
          // this.overlayFlag = false;
        });
    },
    getCustomer: function (event) {
      this.isLoading = true;
      useNetw
        .get("api/registration-document/branch-customer", {
          params: {
            page: this.currentPageCus,
            perPage: this.perPageCus,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            nameTh:
              event != null
                ? event
                : "" || this.form.customerFullNameTh != null
                ? this.form.customerFullNameTh
                : "",
            familyNameTh: this.filter.familyCus,
            mobilePhone: this.filter.mobilePhone,
          },
        })
        .then((response) => {
          //log("C", response);
          this.rowsCus = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecordCus = response.data.total;
          this.to = response.data.to;
          this.totalPageCus = Math.ceil(this.totalRecordCus / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    getCustomerModal: function () {
      this.isLoading = true;
      useNetw
        .get("api/registration-document/branch-customer", {
          params: {
            page: this.currentPageCus,
            perPage: this.perPageCus,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            nameTh: this.filter.nameCus,
            familyNameTh: this.filter.familyCus,
            mobilePhone: this.filter.mobilePhone,
          },
        })
        .then((response) => {
          //log("C", response);
          this.rowsCus = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecordCus = response.data.total;
          this.to = response.data.to;
          this.totalPageCus = Math.ceil(this.totalRecordCus / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    getPayment: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/registration-document/branch-payment", {
          params: {
            page: this.currentPagePay,
            perPage: this.perPagePay, //เฉพาะค้นหา สูงสุด 100
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            paymentCode: event != null ? event : this.filter.paymentCode,
          },
        })
        .then((response) => {
          //log(response);
          this.rowPayment = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecordPay = response.data.total;
          this.to = response.data.to;
          this.totalPagePay = Math.ceil(this.totalRecordPay / per_Page);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getDataloadImage: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/registration-document/full-size-image", {
          params: {
            docId: 2,
            fileId: 327,
          },
        })
        .then((response) => {
          //log(response);
          this.rowImg = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getSale: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/registration-document/branch-vehicle-sale", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
          },
        })
        .then((response) => {
          this.rowSale = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getDataVehicles: function (event) {
      this.isLoading = true;
      useNetw
        .get("api/registration-document/branch-vehicle", {
          params: {
            page: this.currentPageVe,
            perPage: this.perPageVe,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            licensePlate: event != null ? event : this.filter.licensePlate,
            vin: this.filter.vin,
          },
        })
        .then((response) => {
          this.rowsVehi = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecordVe = response.data.total; //response.data.total
          this.to = response.data.to;
          this.totalPageVe = Math.ceil(this.totalRecordVe / per_Page);
          this.form.vin = "";
          this.form.modelNameTh = "";
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    getDocType: function () {
      this.isLoading = true;
      useNetw
        .get("api/registration-document/branch-document-type", {
          params: {
            page: 1,
            perPage: 100,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
          },
        })
        .then((response) => {
          //log("DocType", response);
          this.rowDocType = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    formDocCus() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.form.$invalid != true) {
        this.postSubmit();
      }
    },

    postSubmit() {
      const bodyFormData = new FormData();
      // const imagefile = document.querySelector("#img");

      bodyFormData.append("branchId", this.form.branchId.branchId);
      bodyFormData.append("regJobDate", this.form.regJobDate);
      bodyFormData.append("vehicleId", this.form.vehicleId.vehicleId);
      bodyFormData.append("note", this.form.note);
      bodyFormData.append("cusId", this.form.customerFullNameTh.cusId);
      bodyFormData.append(
        "regId",
        this.form.regCode.regId != undefined ? this.form.regCode.regId : ""
      );
      bodyFormData.append(
        "paymentId",

        this.form.paymentId.paymentId != null &&
          this.form.paymentId.paymentId != undefined
          ? this.form.paymentId.paymentId
          : ""
      );
      bodyFormData.append("isSale", this.form.isSale);

      useNetw
        .post("api/registration-document/store", bodyFormData)

        .then((response) => {
          this.regJobId = response.data.regJobId;
          console.log(response);
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "receiveDocCus-edit",
            params: {
              regJobId: btoa(this.regJobId),
            },
          });
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    handleSearch() {
      if (this.form.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.form.branchId.branchId;
      }
      this.getRegDoc();
    },
    handleSearchVe() {
      if (this.form.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.form.branchId.branchId;
      }
      this.getDataVehicles();
    },
    handleSearchPay() {
      if (this.form.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.form.branchId.branchId;
      }
      this.getPayment();
    },
    handleSearchCus() {
      if (this.form.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.form.branchId.branchId;
      }
      this.getCustomerModal();
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getRegDoc();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getRegDoc();
    },
    handleChangePageVe(page) {
      this.currentPageVe = page;
      this.getDataVehicles();
    },
    handlePageChangeVe(active) {
      this.perPageVe = active;
      this.currentPageVe = 1;
      this.getDataVehicles();
    },

    handleChangePagePay(page) {
      this.currentPagePay = page;
      this.getPayment();
    },
    handlePageChangePay(active) {
      this.perPagePay = active;
      this.currentPagePay = 1;
      this.getPayment();
    },
    handleChangePageCus(page) {
      this.currentPageCus = page;
      this.getCustomerModal();
    },
    handlePageChangeCus(active) {
      this.perPageCus = active;
      this.currentPageCus = 1;
      this.getCustomerModal();
    },
  },
  middleware: "authentication",
};
</script>
 
<style lang="scss" scoped></style>
 

